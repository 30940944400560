.header {
    align-items: center;
    border-bottom: 1px solid rgb(208, 208, 208);
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;

    a {
        margin: 10px auto;
    }
}

.page {
    /* Add margin to center the elements, as a container*/
    background: #f9f7f4;

    form {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      
      .form-section {
        display: grid;
        gap: 16px;
      }
      
      .form-section.columns-2 {
        grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
      }

      .form-section.columns-3 {
        grid-template-columns: repeat(3, 1fr); /* Three columns of equal width */
      }
      
      .form-section.full-width {
        grid-template-columns: 1fr; /* Single column spanning full width */
      }
      
      .form-field {
        display: flex;
        flex-direction: column;
      }
      
      .form-input {
        width: 100%;
      }
      
      label {
        margin-bottom: 8px;
        font-weight: 600;
        color: #87879a;
        font-size: 14px;
      }
      
      input,
      textarea,
      select {
        padding: 8px !important;
        border: 1px solid #0a0a0a;
        border-radius: 4px !important;
        font-size: 14px !important;
      }
      
      button {
        margin-top: 20px !important;
        padding: 10px 20px !important;
        border: none !important;
        background-color: #007bff;
        color: white;
        border-radius: 4px !important;
        cursor: pointer;
      }
      
      button:hover {
        background-color: #0056b3;
      }
  }